<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="REDEEM"
        :filter="filter"
        placeholder="Search Redeem name"
        @search="handleSearch"
        @sidebar="sidebarFilter"
        :hasDropdown="false"
        routerPath="/redeem/detail/0"
      />

      <div class="bg-white border-red px-1 px-sm-3 pb-3 mt-3">
        <b-row class="mt-3">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(redeem_Name)="data">
                <div class="text-left pl-2">
                  <b-button variant="link" class="px-1 py-0">
                    <router-link :to="'/redeem/detail/' + data.item.id">
                      <u>{{ data.item.redeem_Name }}</u>
                    </router-link>
                  </b-button>
                </div>
              </template>
              <template v-slot:cell(total_Redeemable)="data">
                <span class="m-0" v-if="data.item.total_Redeemable">
                  {{ data.item.total_Redeemable | numeral("0,0") }}
                </span>
                <span v-else>Unlimited Rights</span>
              </template>
              <template v-slot:cell(valid_From)="data">
                <div>
                  <span
                    >{{ new Date(data.item.valid_From) | moment($formatDate) }}
                  </span>
                  <span class="text-gray">
                    ({{
                      new Date(data.item.valid_From) | moment($formatTime)
                    }})</span
                  >
                </div>
              </template>
              <template v-slot:cell(valid_To)="data">
                <div>
                  <span
                    >{{ new Date(data.item.valid_To) | moment($formatDate) }}
                  </span>
                  <span class="text-gray">
                    ({{
                      new Date(data.item.valid_To) | moment($formatTime)
                    }})</span
                  >
                </div>
              </template>
              <template v-slot:cell(redeem_Status_name)="data">
                <div>
                  <b v-if="data.item.status == 1" class="text-active">Active</b>
                  <b v-else class="text-danger">Inactive</b>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing {{ filter.start }} to
              {{
                filter.start - 1 + filter.length >= rows
                  ? rows
                  : filter.start - 1 + filter.length
              }}
              of {{ rows }} entires
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.length"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="m-md-0"
              ></b-pagination>
            </div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.length"
                @change="handleSearch"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="submitFilter"
      placeholder="Search Redeem name"
      @clearFilter="clearFilter"
    >
      <template v-slot:checkbox-option
        ><div class="mt-3">
          <div class="font-weight-bold mb-2">Type</div>
          <b-form-group class="mb-2">
            <b-form-checkbox
              v-for="(t, index) in typeOption"
              :key="index"
              :value="t.value"
              v-model="type"
              >{{ t.text }}</b-form-checkbox
            >
          </b-form-group>
        </div>
        <div>
          <div class="mt-3">
            <div class="font-weight-bold mb-2">Transaction Date (start)</div>
            <div class="input-container">
              <datetime
                type="datetime"
                :input-style="styleDatetime"
                v-model="filter.startDate"
                placeholder="DD/MM/YYYY (HH:MM)"
                format="dd/MM/yyyy (HH:mm)"
                value-zone="Asia/Bangkok"
                @close="changeStartDate"
                ref="transferDateStart"
              >
              </datetime>
              <div
                class="icon-container text-right"
                @click="$refs.transferDateStart.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="font-weight-bold mb-2">Transaction Date (end)</div>
            <div class="input-container">
              <datetime
                type="datetime"
                :input-style="styleDatetime"
                v-model="filter.endDate"
                placeholder="DD/MM/YYYY (HH:MM)"
                format="dd/MM/yyyy (HH:mm)"
                value-zone="Asia/Bangkok"
                ref="transferDateEnd"
                :min-datetime="filter.startDate"
              >
              </datetime>
              <div
                class="icon-container text-right"
                @click="$refs.transferDateEnd.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import SideBarFilter from "@/components/SideBarFilter";
export default {
  name: "RedeemIndex",
  components: {
    OtherLoading,
    HeaderPanel,
    Pagination,
    SideBarFilter,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {
          key: "redeem_Name",
          label: "Redeem Name / Description",
        },
        {
          key: "total_Redeemable",
          label: "Exchangeable",
        },
        {
          key: "valid_From",
          label: "Valid From",
        },
        {
          key: "valid_To",
          label: "Valid To",
        },
        {
          key: "redeem_Status_name",
          label: "Status",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        page: 1,
        search: "",
        status: [],
        start: 1,
        length: 10,
        redeemType: "",
        startDate: "",
        endDate: "",
        redeemStatus: "",
      },
      status: [],
      type: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 10,
      sidebarShow: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      typeOption: [
        {
          text: "Single Product",
          value: 1,
        },
        {
          text: "Package",
          value: 2,
        },
      ],
      statusOption: [
        {
          text: "เปิดใช้งาน",
          value: 1,
        },
        {
          text: "ปิดใช้งาน",
          value: 0,
        },
      ],
      statusAll: false,
    };
  },
  computed: {
    selectStatusFilter() {
      if (this.status.length == 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.getList();
  },
  methods: {
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    async getList() {
      this.isBusy = true;
      this.isLoading = true;
      this.filter.redeemStatus = this.filter.status.toString();
      this.filter.redeemType = this.type.toString();
      await this.$store.dispatch("getListRedeem", this.filter);
      const data = this.$store.state.redeem.list;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.recordsTotal;
        this.isBusy = false;
        this.isLoading = false;
      }
    },
    handleSearch() {
      this.filter.start = 1;
      this.filter.page = 1;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.filter.start = (this.filter.page - 1) * this.filter.length + 1;
      this.getList();
    },
    changeStartDate() {
      if (this.filter.startDate && this.filter.endDate) {
        this.filter.endDate =
          this.filter.startDate >= this.filter.endDate
            ? ""
            : this.filter.endDate;
      }
    },
    clearFilter() {
      this.filter = {
        page: 1,
        search: "",
        status: [],
        start: 1,
        length: 10,
        redeemType: "",
        startDate: "",
        endDate: "",
        redeemStatus: "",
      };
      this.status = [];
      this.type = "";
    },
    async submitFilter() {
      this.filter.page = 1;
      this.filter.start = 1;
      await this.getList();
      // this.$refs.filterSidebar.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.text-active {
  color: #27bda7;
}
.text-gray {
  color: #aaaaaa;
}
.input-container {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
</style>
